import React from "react";
import { useNavigate } from "react-router-dom";

import "./ErrorActionsSheet.scss";

import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

interface ProfanityFilterActionSheetProps {
  setShowProfanityFilterActionSheet: (showActionSheet: boolean) => void;
  showProfanityFilterActionSheet: boolean;
}

const ProfanityFilterActionSheet = (props: ProfanityFilterActionSheetProps) => {
  const navigate = useNavigate();

  return (
    <ActionSheet
      color={ActionSheetColor.error}
      title="Oh Sheetz!"
      shouldDisplay={props.showProfanityFilterActionSheet}
      overlay
      cancelFunction={(): void => {
        props.setShowProfanityFilterActionSheet(false);
      }}
      cancelFunctionLabel="Try Again"
    >
      <div className="error-actions-sheet">
        <h1>We&apos;re running into some trouble validating that name!</h1>
        <p>
          Try another one to continue, and remember to use your actual name. If the name is valid,
          &nbsp;
          <SheetzTextButton
            className="contact-us-button"
            textDark
            label="Contact Us"
            onClick={() => navigate("/contact")}
          />
          .
        </p>
      </div>
    </ActionSheet>
  );
};

export default ProfanityFilterActionSheet;
