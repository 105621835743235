import React from "react";
import { useNavigate } from "react-router-dom";

import "./ErrorActionsSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

interface ProfanityFilterActionSheetProps {
  message: string;
  setShowEmailAlreadyInUseActionSheet: (showActionSheet: boolean) => void;
  showEmailAlreadyInUseActionSheet: boolean;
  showLoginButton?: boolean;
}

const EmailAlreadyInUseActionSheet = (props: ProfanityFilterActionSheetProps) => {
  const navigate = useNavigate();

  return (
    <ActionSheet
      color={ActionSheetColor.error}
      title="Son of a Shmiscuit!"
      shouldDisplay={props.showEmailAlreadyInUseActionSheet}
      overlay
    >
      <div className="error-actions-sheet">
        <p>{props.message}</p>

        <div className="button-container">
          <SheetzButton
            className="try-again-button"
            transparentDark
            label="Try again"
            onClick={() => props.setShowEmailAlreadyInUseActionSheet(false)}
          />

          {props.showLoginButton && (
            <SheetzButton
              className="email-button"
              transparentDark
              label="Go to log in"
              onClick={() => navigate("/auth/login")}
            />
          )}
        </div>
      </div>
    </ActionSheet>
  );
};

export default EmailAlreadyInUseActionSheet;
