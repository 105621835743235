import classNames from "classnames";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./Contact.scss";

import { FeedbackRequest, FeedbackSubject } from "assets/dtos/anywhere-dto";

import Header, { HeaderType } from "components/layout/Header/Header";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import SheetzInput, { SelectOption } from "components/misc/form/SheetzInput/SheetzInput";
import SheetzSelect from "components/misc/form/SheetzSelect/SheetzSelect";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import helpDeskSubjects from "resources/helpDeskSubjects";
import states from "resources/states";

import { AppContext } from "util/AppContext.util";
import { getAuthorization } from "util/Authentication.util";
import { getFeedbackSubjects, getFeedbackUserSummary, sendFeedback } from "util/Contact.util";
import { IconType } from "util/Icon.util";
import { getAuthTokenFromNativeMobileBridge, isInNativeMobileContext } from "util/MobileApp.util";
import {
  addressValidation,
  cityValidation,
  emailValidation,
  messageContactValidation,
  nameValidation,
  phoneValidation,
  sheetzCardValidation,
  stateValidation,
  zipValidation,
} from "util/Validation.util";

const Contact = () => {
  const appContext = useContext(AppContext);
  // Break out these methods from appContext to avoid useEffect() lint warning regarding dependencies.
  // Having appContext within the dependencies array is bad - it will cause useEffect() to fire on all renders.
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  let recaptchaRef: string | null = null;
  const [feedbackSubjects, setFeedbackSubjects] = useState<FeedbackSubject[]>();
  const [initialFormValues, setInitialFormValues] = useState<FeedbackRequest>({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    emailAddress: "",
    cardNumber: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    let isUserLoggedIn = getAuthorization() !== null;

    if (isInNativeMobileContext()) {
      const nativeAuthToken = getAuthTokenFromNativeMobileBridge();

      isUserLoggedIn = nativeAuthToken !== null && nativeAuthToken !== undefined;
    }

    showLoading();

    const feedbackSubjectsPromise = getFeedbackSubjects();
    const feedbackUserPromise = isUserLoggedIn ? getFeedbackUserSummary() : undefined;

    Promise.allSettled([feedbackSubjectsPromise, feedbackUserPromise])
      .then((values) => {
        const getFeedbackSubjectsResponse = values[0];
        const getFeedbackUserSummaryResponse = values[1];

        if (getFeedbackUserSummaryResponse.status === "fulfilled") {
          setInitialFormValues({
            firstName: getFeedbackUserSummaryResponse.value?.data.firstName || "",
            lastName: getFeedbackUserSummaryResponse.value?.data.lastName || "",
            address: getFeedbackUserSummaryResponse.value?.data.address || "",
            city: getFeedbackUserSummaryResponse.value?.data.city || "",
            state: getFeedbackUserSummaryResponse.value?.data.stateCode || "",
            zipCode: getFeedbackUserSummaryResponse.value?.data.zipCode || "",
            phoneNumber: getFeedbackUserSummaryResponse.value?.data.phone || "",
            emailAddress: getFeedbackUserSummaryResponse.value?.data.emailAddress || "",
            cardNumber: getFeedbackUserSummaryResponse.value?.data.loyaltyCard || "",
            subject: "",
            message: "",
          });
        }

        if (getFeedbackSubjectsResponse.status === "fulfilled") {
          const subjects = Object.values(getFeedbackSubjectsResponse.value.data.subjectsById);
          setFeedbackSubjects(subjects);
        }
      })
      .finally(hideLoading);
  }, [hideLoading, showLoading]);

  const stateSelectOptions: SelectOption[] = states.map((state) => ({
    label: state.name,
    value: state.abbrev,
  }));

  const helpDeskSubjectOptions: SelectOption[] = (feedbackSubjects ?? helpDeskSubjects).map(
    (subject) => ({
      label: subject.subject,
      value: subject.subjectId.toString(),
    })
  );

  const contactUsFormClassnames = classNames("contact-us-form", {
    "form-submitted": formSubmitted,
  });

  return (
    <>
      <Header type={HeaderType.main} title={"Contact us"} fixed={true} />
      <ResponsiveLayoutContainer>
        <div className={contactUsFormClassnames}>
          {formSubmitted ? (
            <EmptyPage
              detail="We’ll be in touch."
              icon={IconType.check}
              noMargin
              title="Message sent!"
            >
              {!isInNativeMobileContext() && (
                <SheetzButton
                  transparentDark
                  className="empty-page-button"
                  label="Return Home"
                  onClick={(): void => {
                    navigate("/");
                  }}
                />
              )}
            </EmptyPage>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              onSubmit={(values: FeedbackRequest): void => {
                if (recaptchaRef) {
                  setFormSubmitting(true);
                  sendFeedback(values)
                    .then(() => {
                      setFormSubmitted(true);
                    })
                    .finally(() => setFormSubmitting(false));
                } else {
                  appContext.showToast(
                    "Please respond to the ReCAPTCHA",
                    "We like knowing you're a person. Sorry.",
                    ToastType.error
                  );
                }
              }}
              validationSchema={Yup.object({
                emailAddress: emailValidation,
                firstName: nameValidation,
                lastName: nameValidation,
                phoneNumber: phoneValidation,
                address: addressValidation.required("Required"),
                city: cityValidation.required("Required"),
                state: stateValidation.required("Required"),
                zipCode: zipValidation,
                cardNumber: sheetzCardValidation,
                subject: Yup.string().required("Required"),
                message: messageContactValidation,
              })}
            >
              {(formProps: FormikProps<FeedbackRequest>) => (
                <form onSubmit={formProps.handleSubmit}>
                  <SheetzInput
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    onChange={(event) => {
                      formProps.setFieldValue(
                        "firstName",
                        event.currentTarget.value.replace(/‘/g, "'").replace(/’/g, "'")
                      );
                    }}
                  />
                  <SheetzInput
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    onChange={(event) => {
                      formProps.setFieldValue(
                        "lastName",
                        event.currentTarget.value.replace(/‘/g, "'").replace(/’/g, "'")
                      );
                    }}
                  />
                  <SheetzInput type="text" name="address" placeholder="Address" label="Address" />
                  <SheetzInput type="text" name="city" placeholder="City" label="City" />
                  <SheetzSelect
                    name="state"
                    placeholder="State"
                    label="State"
                    options={stateSelectOptions}
                  />
                  <SheetzInput type="text" name="zipCode" placeholder="Zip" label="Zip" />
                  <SheetzInput type="email" name="emailAddress" placeholder="Email" label="Email" />
                  <PatternFormat
                    type="tel"
                    format="(###) ###-####"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    label="Phone Number (optional)"
                    customInput={SheetzInput}
                    onValueChange={(value): void =>
                      formProps.setFieldValue("phoneNumber", value.value)
                    }
                    valueIsNumericString
                  />
                  <SheetzInput
                    type="text"
                    name="cardNumber"
                    label="Sheetz Card Number (optional)"
                  />
                  <br />
                  <SheetzSelect
                    name="subject"
                    placeholder="Subject"
                    label="Subject"
                    options={helpDeskSubjectOptions}
                  />
                  <br />
                  <label className="message-label" htmlFor="message">
                    {"What's on your mind?"}
                  </label>
                  <Field className="message-input" component="textarea" rows="5" name="message" />
                  <div className="message-error">
                    <ErrorMessage name="message" />
                  </div>
                  <br />
                  <div className="recaptcha-row">
                    <ReCAPTCHA
                      sitekey="6LekWSQTAAAAAHuKPLTb3kHsNPyWfgZEeNanL81C"
                      onChange={(value: string | null): void => {
                        recaptchaRef = value;
                      }}
                    />
                  </div>
                  <div className="button-row">
                    <SheetzButton
                      className="submit-button"
                      disabled={formSubmitting}
                      type="button"
                      onClick={(): void => {
                        formProps.validateForm().then((values) => {
                          if (Object.keys(values).length > 0) {
                            const firstErrorName = Object.keys(values)[0];
                            if (firstErrorName) {
                              const element = document.getElementsByName(firstErrorName);
                              element[0].scrollIntoView({ behavior: "smooth", block: "center" });
                            }
                          }
                          formProps.handleSubmit();
                        });
                      }}
                      label="Send"
                    />
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </ResponsiveLayoutContainer>
    </>
  );
};

export default Contact;
