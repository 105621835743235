import { OrderItemActions } from "../../OrderItemReducer";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ItemEvent, MenuCategory } from "assets/dtos/anywhere-dto";

import ItemCustomization from "components/Order/ItemCustomization/ItemCustomization";
import { OrderSubviewProps } from "components/Order/Order";

import { AppContext } from "util/AppContext.util";
import {
  ItemCustomization as ItemCustomizationModel,
  ItemCustomizationSelections,
  ItemSwitchOption,
  SizeSelectOption,
} from "util/Customization.util";
import { CustomizedItem, OrderItemState, getComboDetails } from "util/Order.util";

interface ComboCustomizationProps extends OrderSubviewProps {
  dispatchOrderItem: React.Dispatch<OrderItemActions>;
  evergageAddToBagEvent: (itemRmiId?: number, price?: number) => void;
  evergageViewItemEvent: (
    categories?: number,
    imageUrl?: string,
    itemRmiId?: number,
    name?: string,
    price?: number
  ) => void;
  handleItemSwitchSizeSelection: (
    itemSwitchOption?: ItemSwitchOption,
    sizeSelectOption?: SizeSelectOption
  ) => void;
  orderItemState: OrderItemState;
  resetOrderItemState: () => void;
  setComboTitle?: (comboTitle: string | undefined) => void;
}

const ComboCustomization = (props: ComboCustomizationProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuComboId } = useParams<{ menuComboId: string }>();
  const locationState = location.state as { event?: ItemEvent; previousCategory: MenuCategory };
  const appContext = useContext(AppContext);
  const [comboDetails, setComboDetails] = useState<ItemCustomizationModel[]>([]);

  const menuComboIdNumber = parseInt(menuComboId ?? "");

  if (isNaN(menuComboIdNumber)) {
    throw new SheetzError("Cannot read combo ID.", {
      userReadableMessage: "An error has occurred with your order. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }

  const currentComboItemIndex = props.orderSession.currentComboItemIndex ?? 0;
  const isFirstComboItem = currentComboItemIndex === 0;
  const isLastComboItem = comboDetails.length === currentComboItemIndex + 1;

  useEffect(() => {
    const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;
    // TODO: We'll want to revisit this when implementing deep-linking. The combo data from the menu would be missing if direct linking in.
    if (storeNumber === undefined || props.orderSession.dayPart === undefined) {
      navigate("/order");
    } else {
      appContext.showLoading();
      getComboDetails(
        menuComboIdNumber,
        storeNumber,
        props.orderSession.dayPart,
        props.orderSession.delivery ? "DELIVERY" : "PICKUP",
        props.orderSession.pickupLocation ?? "IN_STORE"
      )
        .then((response): void => {
          props.setComboTitle?.(props.orderSession.combo?.name);
          setComboDetails(response.data.itemCustomizations);
        })
        .finally((): void => appContext.hideLoading());
    }
    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goToNextComboItem(
    itemCustomizationSelections: ItemCustomizationSelections,
    item: CustomizedItem
  ): void {
    if (!comboDetails.length) {
      throw new SheetzError("Cannot continue customizing combo, details are missing.", {
        userReadableMessage:
          "Looks like we're missing some details for your order. Please try again.",
        primaryButton: SheetzErrorButtonType.BACK_TO_ORDER,
      });
    }

    const nextIndex = currentComboItemIndex + 1;

    props.dispatch({ type: "SET_CURRENT_COMBO_ITEM_INDEX", payload: nextIndex });
    props.dispatch({
      type: "SET_COMBO_ITEM_CUSTOMIZATIONS",
      payload: [currentComboItemIndex, itemCustomizationSelections],
    });
    props.dispatch({
      type: "SET_COMBO_ITEM",
      payload: [currentComboItemIndex, item],
    });
    props.dispatch({ type: "CLEAR_ITEM_CUSTOMIZATIONS", payload: undefined });
    navigate(
      "/order/combo/" +
        menuComboIdNumber +
        "/customize/" +
        comboDetails[nextIndex].itemCustomizationId,
      {
        state: {
          event: locationState.event,
          previousCategory: locationState.previousCategory,
        },
        replace: true,
      }
    );
  }

  function goToPreviousComboItem(
    itemCustomizationSelections: ItemCustomizationSelections,
    item: CustomizedItem
  ): void {
    if (!comboDetails.length) {
      throw new SheetzError("Cannot continue customizing combo, details are missing.", {
        userReadableMessage:
          "Looks like we're missing some details for your order. Please try again.",
        primaryButton: SheetzErrorButtonType.BACK_TO_ORDER,
      });
    }

    const previousIndex = currentComboItemIndex - 1;
    props.dispatch({ type: "SET_CURRENT_COMBO_ITEM_INDEX", payload: previousIndex });
    props.dispatch({
      type: "SET_COMBO_ITEM_CUSTOMIZATIONS",
      payload: [currentComboItemIndex, itemCustomizationSelections],
    });
    props.dispatch({
      type: "SET_COMBO_ITEM",
      payload: [currentComboItemIndex, item],
    });
    props.dispatch({ type: "CLEAR_ITEM_CUSTOMIZATIONS", payload: undefined });
    navigate(
      "/order/combo/" +
        menuComboIdNumber +
        "/customize/" +
        comboDetails[previousIndex].itemCustomizationId,
      {
        state: {
          event: locationState.event,
          previousCategory: locationState.previousCategory,
        },
        replace: true,
      }
    );
  }

  return props.orderSession.combo && comboDetails.length ? (
    <ItemCustomization
      orderSession={props.orderSession}
      dispatch={props.dispatch}
      dispatchOrderItem={props.dispatchOrderItem}
      evergageAddToBagEvent={props.evergageAddToBagEvent}
      evergageViewItemEvent={props.evergageViewItemEvent}
      combo={props.orderSession.combo}
      currentComboItemIndex={currentComboItemIndex}
      numberOfItemsInCombo={comboDetails.length}
      comboItem={comboDetails[currentComboItemIndex]}
      existingComboItemCustomizations={
        props.orderSession.comboItemCustomizationSelections?.[currentComboItemIndex]
      }
      goToNextComboItem={goToNextComboItem}
      goToPreviousComboItem={goToPreviousComboItem}
      handleItemSwitchSizeSelection={props.handleItemSwitchSizeSelection}
      isFirstComboItem={isFirstComboItem}
      isLastComboItem={isLastComboItem}
      orderItemState={props.orderItemState}
      resetOrderItemState={props.resetOrderItemState}
    />
  ) : (
    <></>
  );
};

export default ComboCustomization;
