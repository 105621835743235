import { DateTime } from "luxon";
import * as Yup from "yup";

export const emailValidation = Yup.string()
  .email("Invalid Email Address")
  .required("Required")
  .max(256);

export const employeeIdValidation = Yup.string()
  .length(6, "Employee Id must be exactly 6 digits")
  .matches(new RegExp("^[0-9]{6}$"));

// FirstName, lastname
export const nameValidation = Yup.string()
  .required("Required")
  .max(32)
  .matches(new RegExp("^[A-Za-z\\s'-]+$"), "Name can only use (A-Z, spaces, ', -).");

export const phoneValidation = Yup.string()
  .min(10, "Phone Number must be ten digits")
  .max(10, "Phone Number must be ten digits")
  .matches(new RegExp("^[0-9]{10}$"), "Invalid phone number");

export const dateMonthValidation = Yup.string().required("Required");

export const addressValidation = Yup.string()
  .max(128)
  .matches(new RegExp("^[0-9a-zA-Z-./ ]+$"), "Address contains invalid symbol.");

export const cityValidation = Yup.string()
  .max(128)
  .matches(new RegExp("^[0-9a-zA-Z-. ]+$"), "City contains invalid symbol.");

export const stateValidation = Yup.string()
  .max(2)
  .matches(new RegExp("^[A-Z]+$"), "State must be a two-letter abbreviation.");

export const zipValidation = Yup.string()
  .required("Required")
  .matches(new RegExp("^[0-9]{5}$"), "Please enter a valid zip code")
  .min(5, "Zip Code must be five digits")
  .max(5, "Zip Code must be five digits");

export const sheetzCardNameValidation = Yup.string()
  .max(32, "At most 32 characters long")
  .matches(new RegExp("^[0-9a-zA-Z-. ]+$"), "Name may only contain A-Z");

export const giftCardPinValidation = Yup.string()
  .required("Required")
  .min(3, "At least 3 digits long")
  .max(4, "At most 4 digits long");

export const sheetzCardValidation = Yup.string()
  .length(16, "Card number must be exactly 16 digits")
  .matches(new RegExp("^[0-9]{16}$"));

export const giftCardValidation = Yup.string()
  .required("Required")
  .min(19, "Card number must be 19 digits long")
  .max(19, "Card number must be 19 digits long");

export const creditCardValidation = Yup.string()
  .required("Required")
  .max(16, "Credit Card cannot exceed 16 digits")
  .min(15, "Credit Card must be at least 15 digits");

export const securityCodeValidation = Yup.string()
  .required("Required")
  .matches(new RegExp("^[0-9]{3,4}$"), "CVV must be three or four digits");

export const messageContactValidation = Yup.string()
  .required("Required")
  .matches(
    /^[a-zA-Z0-9\-.,:;'’!#?/%$\s]+$/,
    "Message contains invalid characters. Please only use letters, numbers, spaces, and the following: -.,:;'’!#?/%$"
  )
  .min(1, "You need to tell us something!")
  .max(1600, "Your message is too long!");

export const searchValidation = Yup.string().max(256);

export const ageBirthDateVerifyAge = Yup.string()
  .required("Required")
  .test(
    "test-birthdate-age-verify",
    "Birthdate is invalid",
    (value: string | undefined): boolean => {
      if (value && value.toString().length === 8) {
        const date = DateTime.fromFormat(value, "MMddyyyy");

        return (
          date.isValid && date.year > 1900 && date < DateTime.now().setZone("America/New_York")
        );
      }

      return false;
    }
  );

export const ageBirthDateRegistration = Yup.string()
  .required("Required")
  .test(
    "test-birthdate-registration",
    "Birthdate is invalid or user is not at least 13 years old",
    (value: string | undefined): boolean => {
      if (value && value.toString().length === 8) {
        const date = DateTime.fromFormat(value, "MMddyyyy");

        return (
          date.isValid &&
          date.year > 1900 &&
          date <= DateTime.now().setZone("America/New_York").minus({ years: 13 })
        );
      }

      return false;
    }
  );

export const passwordValidation = Yup.string()
  .required("Required")
  .max(256)
  .min(8)
  .matches(
    new RegExp("^[0-9a-zA-Z`!@#$%^&*()_\\[+={}\\/:;\"'.,?\\-~|<>\\]]+$"),
    "Invalid character"
  )
  .matches(new RegExp("^.*[A-Z].*"), "Password must contain at least one uppercase letter")
  .matches(new RegExp("^.*[a-z].*"), "Password must contain at least one lowercase letter")
  .matches(new RegExp("^.*[0-9].*"), "Password must contain at least one number")
  .matches(
    new RegExp("^.*[`!@#$%^&*()_\\[+={}\\/:;\"'.,?\\-~|<>\\]]+"),
    "Password must contain at least one special character"
  );
