import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Security.scss";

import CreatePin from "components/Account/MyWallet/Pin/CreatePin/CreatePin";
import PinEntry from "components/Account/MyWallet/Pin/PinEntry/PinEntry";
import SheetzToggle from "components/misc/form/SheetzToggle/SheetzToggle";
import PathListItem from "components/misc/list/PathListItem/PathListItem";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import {
  isInNativeMobileContext,
  setShowCreateUpdatePin,
  showBioMetricsStatus,
} from "util/MobileApp.util";
import { changeUserPin, createUserPin, verifyPinStillValid } from "util/Pin.util";
import { getPinAuthToken, setPinAuthToken } from "util/Storage.util";

interface SecurityProps {
  isBioMetricsEnabled?: boolean;
  pinExists: boolean;
  setPinExists: (pinExists: boolean) => void;
}

const Security = (props: SecurityProps) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [showCreatePin, setShowCreatePin] = useState(false);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [pinServerSaveError, setPinServerSaveError] = useState(false);
  const [pinServerValidationError, setPinServerValidationError] = useState("");
  const [editPin, setEditPin] = useState(false);

  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  function toggleBioMetrics(): void {
    showBioMetricsStatus(!props.isBioMetricsEnabled);
  }

  function onCreateChangePinClicked(): void {
    if (isInNativeMobileContext()) {
      setShowCreateUpdatePin().then((response) => {
        if (response !== undefined) {
          props.setPinExists(response);
        }
      });

      return;
    }

    if (props.pinExists) {
      setPinServerValidationError("");
      setShowPinEntry(true);
    } else {
      setShowCreatePin(true);
    }
  }

  function createPin(pin: string): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    showLoading();

    createUserPin(pin)
      .then(
        (response) => {
          setShowCreatePin(false);
          props.setPinExists(true);
          setPinAuthToken(response.data.jwtToken);
          appContext.showToast("Settings Saved!", "", ToastType.success);
        },
        () => {
          setPinServerSaveError(true);
        }
      )
      .finally(hideLoading);
  }

  function changePin(pin: string): void {
    const pinAuthJWT = getPinAuthToken();
    const isPinStillValid = verifyPinStillValid();
    if (!isPinStillValid) {
      appContext.showToast("Oh Sheetz!", "Your Pin session has expired!", ToastType.error);
      return;
    }

    if (pinAuthJWT) {
      setPinServerValidationError("");
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      showLoading();

      changeUserPin(pin, pinAuthJWT.jwtToken)
        .then(
          (response) => {
            setShowCreatePin(false);
            setPinAuthToken(response.data.jwtToken);
            appContext.showToast("Settings Saved!", "", ToastType.success);
          },
          (error) => setPinServerValidationError(error.response?.data.message)
        )
        .finally(hideLoading);
    }
  }

  function closeCreatePin(): void {
    setShowCreatePin(false);
  }

  function closePinEntry(authenticated: boolean): void {
    setShowPinEntry(false);
    if (authenticated) {
      setShowCreatePin(true);
      setEditPin(true);
    } else {
      setShowPinEntry(false);
    }
  }

  const pinEntryActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      title="ENTER PIN"
      shouldDisplay={showPinEntry}
      overlay={true}
    >
      {showPinEntry && <PinEntry verifyPinCallback={closePinEntry} />}
    </ActionSheet>
  );

  const pinCreateActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      text={editPin ? "" : "LOCK IT UP!"}
      title={editPin ? "EDIT PIN" : "CREATE A PIN"}
      shouldDisplay={showCreatePin}
      overlay={true}
      required
    >
      {showCreatePin && (
        <CreatePin
          editPin={editPin}
          closeCreatePinCallback={closeCreatePin}
          savePinCallback={editPin ? changePin : createPin}
          serverSaveError={pinServerSaveError}
          serverValidationError={pinServerValidationError}
        />
      )}
    </ActionSheet>
  );

  const sheetzToggle = (
    <div className="toggle">
      <SheetzToggle clickHandler={toggleBioMetrics} checked={props.isBioMetricsEnabled} />
    </div>
  );

  return (
    <div className="security-container">
      <>
        <PathListItem
          title="Update Password"
          clickHandler={(): void => navigate("/account/settings/security/changePassword")}
        />
        <PathListItem
          title="Update Pin"
          clickHandler={(): void => onCreateChangePinClicked()}
          hideBottomBorder={!isInNativeMobileContext()}
        />
        {isInNativeMobileContext() && props.isBioMetricsEnabled !== undefined && (
          <PathListItem
            title="Biometrics"
            hideArrow
            rightButtonContent={sheetzToggle}
            hideBottomBorder
          />
        )}
      </>

      {showCreatePin && pinCreateActionSheet}
      {showPinEntry && pinEntryActionSheet}
    </div>
  );
};

export default Security;
