import React, { ReactElement, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import "./Auth.scss";

import CodeEntry from "components/Auth/CodeEntry/CodeEntry";
import Forgot from "components/Auth/Forgot/Forgot";
import Login from "components/Auth/Login/Login";
import Register from "components/Auth/Register/Register";
import ResetPassword from "components/Auth/ResetPassword/ResetPassword";

export enum AuthBannerType {
  sandwich = "sandwich",
  msc = "msc",
}

const Auth = (): ReactElement => {
  const [registrationAuthHeader, setRegistrationAuthHeader] = useState<string>("Get Started");
  const [registrationBannerType, setRegistrationBannerType] = useState(AuthBannerType.sandwich);
  const location = useLocation();

  let authBannerType: AuthBannerType = AuthBannerType.sandwich;
  let authBannerText: string;

  switch (location.pathname) {
    case "/auth/login":
      authBannerText = "Welcome Back!";
      break;
    case "/auth/register":
      authBannerType = registrationBannerType;
      authBannerText = registrationAuthHeader;
      break;
    case "/auth/forgot":
      authBannerText = "Trouble Logging In?";
      break;
    case "/auth/resetPassword":
      authBannerText = "Reset Password";
      break;
    case "/auth/codeEntry":
      authBannerText = "Got a Reset Code?";
      break;
    default:
      authBannerText = "Welcome Back!";
  }

  return (
    <>
      <div className={"auth " + authBannerType.toString()}>
        <div className="authContainer">
          <div className={"content " + authBannerType.toString()}>
            <div className="authTitle">{authBannerText}</div>
            <div className="authChild">
              {/* Parent route is `/auth` */}
              <Routes>
                <Route path="codeEntry" element={<CodeEntry />} />
                <Route path="forgot" element={<Forgot />} />
                <Route path="login" element={<Login />} />
                <Route
                  path="register"
                  element={
                    <Register
                      setBanner={(bannerType: AuthBannerType): void =>
                        setRegistrationBannerType(bannerType)
                      }
                      setAuthHeader={(registrationAuthHeader: string): void =>
                        setRegistrationAuthHeader(registrationAuthHeader)
                      }
                    />
                  }
                />
                <Route path="resetPassword" element={<ResetPassword />} />
                <Route path="/*" element={<Navigate to="/auth/login" replace />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
