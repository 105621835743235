import classNames from "classnames";
import React, { PropsWithChildren, useEffect, useState } from "react";

import "./ActionSheet.scss";

import { ReactComponent as BottomSwoopSkinny } from "assets/swoops/action_swoop_bottom_skinny.svg";
import { ReactComponent as TopSwoopSkinny } from "assets/swoops/action_swoop_top_skinny.svg";
import { ReactComponent as DesktopTopSwoop } from "assets/swoops/action_swoop_top_skinny_desktop.svg";

import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { useMediaQuery } from "hooks";

export enum ActionSheetColor {
  green = "green",
  currency = "currency",
  red = "red",
  pay = "pay",
  error = "error",
}

interface ActionSheetProps {
  shouldDisplay: boolean;
  title: string;
  color: ActionSheetColor;
  text?: string;
  onOverlayClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  overlay: boolean;
  required?: boolean;
  edgeToEdge?: boolean;
  cancelFunction?: () => void;
  cancelFunctionLabel?: string;
  closeCallback?: (close: boolean) => void;
  onAnimationOutFinished?: () => void;
}

const ActionSheet = (props: PropsWithChildren<ActionSheetProps>) => {
  const [render, setRender] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [useDesktopView] = useMediaQuery("(min-width:500px)");
  const closeCallback = props.closeCallback;

  useEffect(() => {
    if (props.shouldDisplay) {
      setRender(true);
      setTimeout(() => {
        setShow(true);
      }, 300);
    } else {
      setShow(false);
      setTimeout(() => {
        setRender(false);
      }, 300);
      // TODO: We should probably get rid of this prop in favor of onAnimationOutFinished
      if (closeCallback) {
        setTimeout(() => {
          closeCallback(true);
        }, 900);
      }
      if (props.onAnimationOutFinished) {
        setTimeout(() => {
          props.onAnimationOutFinished?.();
        }, 900);
      }
    }

    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldDisplay]);

  const secondaryText = props.text ? <div className="text">{props.text}</div> : <></>;

  const content = props.children ? (
    <div className={"action-sheet-content" + (props.required ? " required" : "")}>
      {props.children}
    </div>
  ) : (
    <></>
  );

  const actionSheetContainerClasses = classNames("action-sheet-container", {
    show: render,
    "edge-to-edge": props.edgeToEdge,
  });

  const actionSheetOverlayClasses = classNames("action-sheet-overlay", {
    show: show,
  });

  const actionSheetClasses = classNames("action-sheet", {
    content: props.children,
    show: show,
  });

  return render ? (
    <div className={actionSheetContainerClasses}>
      {props.overlay ? (
        <div
          className={actionSheetOverlayClasses}
          onClick={props.onOverlayClick ? props.onOverlayClick : undefined}
          role="button"
          tabIndex={0}
        />
      ) : (
        <></>
      )}
      <div className={actionSheetClasses}>
        <div className="action-sheet-header">
          {useDesktopView ? (
            <DesktopTopSwoop className={"top-swoop swoop " + props.color.toString()} />
          ) : (
            <TopSwoopSkinny className={"top-swoop swoop " + props.color.toString()} />
          )}
          <div className={"action-sheet-header-content " + props.color.toString()}>
            <div className="title">{props.title}</div>
            {secondaryText}
          </div>
          <BottomSwoopSkinny
            className={
              "bottom-swoop swoop " + props.color.toString() + (props.children ? " content" : "")
            }
          />
        </div>
        {content}
        {!props.required && props.cancelFunction && (
          <div className="action-sheet-footer">
            <SheetzTextButton
              textDark
              className="action-sheet-cancel-button"
              label={props.cancelFunctionLabel ?? "Cancel"}
              type="button"
              onClick={props.cancelFunction}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ActionSheet;
