import React, { ReactElement, useState } from "react";
import { PatternFormat } from "react-number-format";

import { GetUserProfileResponse } from "assets/dtos/anywhere-dto";

import ChangePhoneNumber from "components/Order/Curbside/ChangePhoneNumber/ChangePhoneNumber";
import OrderDetailListItem from "components/Order/OrderConfirmation/OrderDetailsListItem/OrderDetailListItem";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";

import { IconType } from "util/Icon.util";

interface OrderDeliveryPhoneProps {
  phoneNumber?: string;
  setUserProfile?: (userProfile: GetUserProfileResponse | undefined) => void;
  userProfile?: GetUserProfileResponse;
  dispatch?: React.Dispatch<OrderSessionAction>;
  displayOnly?: boolean;
}

const OrderDeliveryPhone = (props: OrderDeliveryPhoneProps): ReactElement | null => {
  const [showChangePhoneNumberModal, setShowChangePhoneNumberModal] = useState<boolean>(false);

  if (props.phoneNumber === undefined) {
    return null;
  }

  function changePhoneNumberRequestClose(): void {
    setShowChangePhoneNumberModal(false);
  }

  function onPhoneNumberChanged(newPhoneNumber: string): void {
    setShowChangePhoneNumberModal(false);
    props.dispatch?.({ type: "SET_DELIVERY_PHONE_NUMBER", payload: newPhoneNumber });
    props.setUserProfile?.({
      ...props.userProfile,
      phoneNumber: newPhoneNumber || "",
    } as GetUserProfileResponse);
  }

  return (
    <>
      <OrderDetailListItem
        action={props.displayOnly ? undefined : (): void => setShowChangePhoneNumberModal(true)}
        actionTitle="Update"
        icon={IconType.phone}
        iconClass={"phone-icon"}
        showAction={!props.displayOnly}
      >
        <p>
          <PatternFormat
            value={props.phoneNumber}
            displayType="text"
            format="###-###-####"
            valueIsNumericString
          />
        </p>
      </OrderDetailListItem>

      {showChangePhoneNumberModal && props.userProfile !== undefined && (
        <SheetzModal
          className="change-phone-number-modal"
          isOpen={showChangePhoneNumberModal}
          closeFunction={changePhoneNumberRequestClose}
          contentLabel="Change my phone number"
          onRequestClose={changePhoneNumberRequestClose}
          shouldCloseOnOverlayClick={false}
          headerText="Change my phone number"
        >
          <ChangePhoneNumber
            userProfile={props.userProfile}
            onPhoneNumberChanged={onPhoneNumberChanged}
          />
        </SheetzModal>
      )}
    </>
  );
};

export default OrderDeliveryPhone;
