import React from "react";

import { Option, Selector } from "assets/dtos/anywhere-dto";

import SelectorContainer from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import ItemSwitchSelector from "components/Order/ItemCustomization/Selectors/ItemSwitchSelector/ItemSwitchSelector";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import {
  ItemCustomization,
  ItemSwitchOption,
  findItemSwitchSelector,
  handleTagAlongTags,
} from "util/Customization.util";
import { CustomizedItem } from "util/Order.util";
import { deriveCondiment, deriveSwitch, getAllTags } from "util/Tag.util";

export function deriveItemSwitches(
  selector: Selector,
  options: Option[],
  itemCustomization: ItemCustomization
): ItemSwitchOption[] {
  const itemSwitchOptions = options.map((option) => {
    if (!option.switchTags) {
      return null;
    }

    const associatedRi = deriveSwitch(
      option.switchTags,
      itemCustomization,
      itemCustomization.retailItem.switches
    );

    // If the option and its switchTags cannot derive a switchRI, then this option should not be displayed to the customer.
    if (associatedRi === null) {
      return null;
    }

    return {
      option: option,
      switchRi: associatedRi,
      switchRmi: associatedRi?.retailModifiedItems[0],
    } as ItemSwitchOption;
  });

  if (selector.noOption) {
    const noOption = createNoOption(selector.noOption, itemCustomization);
    if (noOption) {
      itemSwitchOptions.push(noOption);
    }
  }

  // Filter out any elements that are null and log a warning if there aren't any options left.
  const filteredOptions = itemSwitchOptions.filter(
    (option) => option !== null
  ) as ItemSwitchOption[];
  if (!filteredOptions.length) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`No options derived a switch for item switch selector with name='${selector.text}'
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
  }

  return filteredOptions;
}

function createNoOption(
  noOption: Option,
  itemCustomization: ItemCustomization
): ItemSwitchOption | undefined {
  if (!noOption.switchTags) {
    return;
  }
  const noOptionSwitch = deriveSwitch(
    noOption.switchTags,
    itemCustomization,
    itemCustomization.retailItem.switches
  );
  const itemSwitchOption = {
    option: noOption,
    switchRi: noOptionSwitch,
    switchRmi: noOptionSwitch?.retailModifiedItems[0],
  } as ItemSwitchOption;
  return itemSwitchOption;
}

/**
 * This function is used for managing/displaying the item switch selector as an action sheet from the Menu or when an AIU was selected within customization.
 */
export function createItemSwitchSelector(
  cancelFunction: () => void,
  displayActionSheet: boolean,
  onItemSwitchSelected: (itemSwitchOption: ItemSwitchOption) => void,
  itemCustomization?: ItemCustomization,
  closeCallback?: (close: boolean) => void
): JSX.Element | undefined {
  let itemSwitchSelector: JSX.Element | null;
  let selector: Selector | undefined;

  if (!itemCustomization) {
    itemSwitchSelector = null;
  } else {
    selector = findItemSwitchSelector(itemCustomization.template);
  }

  if (!selector || !selector.options || !itemCustomization || selector.type !== "ITEM_SWITCH") {
    return;
  } else {
    const itemSwitchOptions = deriveItemSwitches(selector, selector.options, itemCustomization);

    if (!itemSwitchOptions.length) {
      itemSwitchSelector = null;
    }
    itemSwitchSelector = (
      <ItemSwitchSelector
        selector={selector}
        itemSwitchOptions={itemSwitchOptions}
        onItemSwitchSelected={onItemSwitchSelected}
      />
    );
  }

  const actionSheet = itemCustomization ? (
    <ActionSheet
      color={ActionSheetColor.red}
      title={selector?.text ?? ""}
      shouldDisplay={!!displayActionSheet}
      cancelFunction={cancelFunction}
      closeCallback={closeCallback}
      overlay={true}
    >
      {itemSwitchSelector}
    </ActionSheet>
  ) : (
    <></>
  );

  return actionSheet;
}

export function processItemSwitchSelector(
  selector: Selector,
  selectorState: ItemSwitchOption | undefined,
  item: CustomizedItem,
  itemCustomization: ItemCustomization,
  dispatch: React.Dispatch<OrderSessionAction>,
  index: number
): JSX.Element | null {
  if (!selector.options || !selector.options.length) {
    // TODO: Possibly throw error instead that is caught and logged to splunk.
    console.warn(`Item Switch selector has no options switchText='${selector.text}', 
    and itemCustomizationId='${itemCustomization.itemCustomizationId}'`);
    return null;
  }

  const itemSwitchOptions = deriveItemSwitches(selector, selector.options, itemCustomization);
  if (!itemSwitchOptions.length) {
    return null;
  }

  let selectedOption = selectorState;
  if (
    !itemSwitchOptions.find(
      (itemSwitchOption) => itemSwitchOption.option.text === selectedOption?.option.text
    )
  ) {
    selectedOption = undefined;
  } else if (selectedOption) {
    item.retailItem = selectedOption.switchRi;
    item.retailModifiedItem = selectedOption.switchRmi;

    // If the selected option has a selected inline sub-option selected, then derive that condiment and add it to the item.
    if (selectedOption.selectedInlineSubOption?.tags) {
      const inlineSubCondiment = deriveCondiment(
        selectedOption.selectedInlineSubOption.tags,
        getAllTags(item),
        itemCustomization,
        false
      );
      if (inlineSubCondiment) {
        item.condiments.push(inlineSubCondiment);
      }
    }

    handleTagAlongTags(selectedOption.option, itemCustomization, item);
  }

  // If we are displaying the selector as an action sheet, we don't need to render it in the body.
  if (selector.displayAsActionSheet) {
    return null;
  }

  const handleItemSwitchSelected = (itemSwitchOption: ItemSwitchOption): void => {
    dispatch({
      type: "RESET_ITEM_CUSTOMIZATIONS",
      payload: [selector.text ?? "", itemSwitchOption],
    });
  };

  return (
    <SelectorContainer key={index} selector={selector}>
      <ItemSwitchSelector
        selector={selector}
        itemSwitchOptions={itemSwitchOptions}
        selectedOption={selectedOption}
        onItemSwitchSelected={handleItemSwitchSelected}
      />
    </SelectorContainer>
  );
}
