import React from "react";

import "./Notifications.scss";

import { UpdateUserProfileRequest } from "assets/dtos/anywhere-dto";

import { AccountFormValues } from "components/Account/AccountSettings/AccountSettings";
import PersonalInformation from "components/Account/AccountSettings/MyInformation/PersonalInformation/PersonalInformation";

import { setPageTitleForMobileHeader } from "util/MobileApp.util";

interface NotificationProps {
  accountFormValues: AccountFormValues;
  isPushNotificationsEnabled?: boolean;
  updateUserProfileInformation: (userProfileValues: UpdateUserProfileRequest) => void;
}

const Notifications = (props: NotificationProps) => {
  setPageTitleForMobileHeader("Notifications");

  return (
    <div className="notifications-container">
      <PersonalInformation
        accountFormValues={props.accountFormValues}
        emailSettings
        isPushNotificationsEnabled={props.isPushNotificationsEnabled}
        updateUserProfileInformation={props.updateUserProfileInformation}
      />
    </div>
  );
};

export default Notifications;
